<template>
  <section class="monitor-wrap">
    <el-button type="primary" size="mini" @click="addMonitor">添加监测主题</el-button>
    <el-upload
      action="fdsfs"
      :multiple="false"
      :show-file-list="false"
      :http-request="addFiles"
      :limit="1"
      size="mini"
      style="display: inline-block;margin-left: 10px;"
      >
<!--      <el-button size="mini">Excel导入监测主题</el-button>-->
    </el-upload>
    <div class="tree-table">
      <div class="header">
        <p class="th-1">监测主题管理</p>
        <p class="th-2">操作</p>
      </div>
      <el-tree
        v-loading="treeLoading"
      :data="data"
      node-key="id"
      :highlight-current="true"
      :default-expand-all="true"
      :expand-on-click-node="true">
        <span class="custom-tree-node" slot-scope="{node, data }">
          <!-- <i class="el-icon-plus"></i> -->
          <span >{{ node.label }}</span>
          <span>
            <el-button v-if="userRole !== '平台运营人员'"
              type="text"
              size="mini"
              @click="addCategory(data.id,data.name,data.subjectId)"
            >
              添加分类
            </el-button>
<!--            <el-button-->
<!--              type="text"-->
<!--              size="mini"-->
<!--              @click="togo(data.id)"-->
<!--            >-->
<!--              分类规则-->
<!--            </el-button>-->
            <el-button v-if="userRole !== '平台运营人员'"
              type="text"
              size="mini"
              @click="editMonitor(data.id,data.pid)"
            >
              修改
            </el-button>
            <el-button v-else
              type="text"
              size="mini"
              @click="editMonitor(data.id,data.pid)"
            >
              查看
            </el-button>
<!--            <el-button-->
<!--              type="text"-->
<!--              size="mini"-->
<!--              @click="goKeyWord(node.id)"-->
<!--            >-->
<!--              关键词-->
<!--            </el-button>-->
            <el-button v-if="userRole !== '平台运营人员'"
              type="text"
              size="mini"
              @click="removeMonitor(data.id, data.pid)"
            >
              删除
            </el-button>
          </span>
        </span>
      </el-tree>
    </div>
    <el-dialog title="提示" :visible.sync="dialog.visible" class="dialog" width="30%" top="30vh" v-loading="delLoading">
      <span class="prompt"><i class="el-icon-warning"></i>确定删除？</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog.visible = false">取 消</el-button>
        <el-button type="primary" @click="define">确 定</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { showMonitorSubject, delectSubject, delectSort } from '@/api/settings/MonitorSubject'
import eventHub from '@/utils/eventHub'
import axios from 'axios'
import { getToken } from '@/utils/auth'
import { apiBaseURL } from '@/utils/request'
import { getNav } from '@/api/layout'
import {listToTree} from '@/utils/index.js'
export default {
  data () {
    return ({
      delLoading: false,
      treeLoading: true,
      data: [],
      dialog: {
        title: '',
        visible: false,
        type: ''
      },
      fileList: [],
      form: {
        name: ''
      },
      cid: '',
      pid: ''
    })
  },
  methods: {
    // 获取到文件
    addFiles (item) {
      console.log(this.$route.params.id + '100')
      if (this.$route.params.id === undefined) {
        this.cid = null
      } else {
        this.cid = this.$route.params.id
      }
      let fd = new FormData()
      // 传文件
      fd.append('file', item.file)
      fd.append('cid', this.cid)
      let requestConfig = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'token': getToken()
        }
      }
      axios.post(apiBaseURL + `/cus/excel`, fd, requestConfig).then((res) => {
        console.log(res)
        if (res.data.code === 0) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    async viewdelectSort (id, cid) {
      try {
        const res = await delectSort({ id: id, cid: cid })
        // if (res.code === 201) {
        //   this.$alert('当前主题下存在次级分类，请删除后再试', '提示', {
        //     confirmButtonText: '确定',
        //     type: 'warning'
        //   })
        // }
        this.viewShowMonitorSubject(this.cid)
        return res
      } catch (err) {
        console.log(err)
      }
    },
    async viewShowMonitorSubject (cid) {
      try {
        const res = await showMonitorSubject({ cid: cid })
        this.data = res.data
        this.refreshMenu()
      } catch (err) {
        console.log(err)
      }
      this.treeLoading = false
    },
    async viewdelectSubject (id, cid) {
      try {
        const res = await delectSubject({ id: id, cid: cid })
        // if (res.code === 201) {
        //   this.$alert('当前主题下存在次级分类，请删除后再试', '提示', {
        //     confirmButtonText: '确定',
        //     type: 'warning'
        //   })
        // }
        this.viewShowMonitorSubject(this.cid)
        return res
      } catch (err) {
        console.log(err)
      }
    },
    addMonitor () {
      this.$router.push('/settings/theme/add/' + this.cid)
    },
    removeMonitor (id, pid) {
      this.dialog.visible = true
      this.id = id
      this.pid = pid
    },
    addCategory (id, typeName, subjectId) {
      this.$router.push(`/settings/theme/addclass/${id}/${typeName}/${subjectId}/${this.cid}`)
    },
    editMonitor (id, pid) {
      console.log(id)
      console.log(pid)
      if (pid !== '0') {
        // this.$router.push({
        //   name: 'editclassDate',
        //   params: {
        //     id: id,
        //     cid: this.cid,
        //     pid: pid
        //   }
        // })
        this.$router.push(`/settings/theme/editclass/${id}/${this.cid}/${pid}`)
      } else {
        this.$router.push(`/settings/theme/edit/${id}/${this.cid}`)
      }
    },
    async refreshMenu () {
      const res = await getNav()
      const [tree,map] = listToTree(res.data)
      this.$store.commit('app/SET_NAV_LIST',tree)
      sessionStorage.setItem('navMap',JSON.stringify(map))
    },
    async define () {
      this.delLoading = true
      this.cid = this.$route.params.id
      console.log(this.pid)
      if (this.pid === '0') {
        const res = await this.viewdelectSubject(this.id, this.cid)
        console.log(res)
        if (res.code === 0) {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          // TODO 删除主题后页面跳转没错
          this.dialog.visible = false
          // this.refreshMenu()
        } else {
          this.$message.error(res.msg)
          // TODO 删除主题后页面跳转没错
          this.dialog.visible = false
          // this.refreshMenu()
        }
      } else {
        this.$message.info('此操作会同时删除分类下的新闻，时间可能较长，请耐心等待')
        const res = await this.viewdelectSort(this.id, this.cid)
        console.log(res)
        if (res.code === 0) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          // TODO 删除主题后页面跳转没错
          this.dialog.visible = false
          // this.refreshMenu()
        } else {
          this.$message.error(res.msg)
          // TODO 删除主题后页面跳转没错
          this.dialog.visible = false
          // this.refreshMenu()
        }
      }
      this.delLoading = false
    },
    togo (id) {
      this.$router.push(`/settings/theme/classification/${id}`)
    },
    goKeyWord (id) {
      this.$router.push(`/settings/theme/keyWord/${id}`)
    }
  },
  mounted () {
    this.cid = this.$route.params.id
    this.viewShowMonitorSubject(this.cid)
    eventHub.$emit('msg', '监测主题')
    this.userRole = JSON.parse(sessionStorage.getItem('curr_custom')).userRole
  },
  beforeDestroy () {
    eventHub.$off('cid')
  },
  computed: {
    // 监听数据
    getCid () {
        eventHub.$on('cid', value => {
          this.cid = value
        })
      return this.cid
    }
  },
  watch: {
    //  监听改变事件
    getCid: {
      handler (newValue, oldValue) {
        this.viewShowMonitorSubject(newValue)
      }
    }
  }
}
</script>

<style lang="scss">
.monitor-wrap {
  width: 100%;
  height: 89%;
  background: #fff;
  border-radius: 2px;
  padding: 20px 20px;
  box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
  .tree-table {
    width: 100%;
    height: 90%;
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    border-collapse: collapse;
    .header {
      font-size: 14px;
      height: 40px;
      box-sizing: border-box;
      border-bottom: 1px solid #ccc;
      line-height: 40px;
      p {
        margin: 0;
        display: inline-block;
        padding: 0 10px;
      }
      .th-1 {
        width: 50%;
      }
      .th-2 {
        width: 50%;
        text-align: center;
      }
    }
    .el-tree {
      padding: 5px 20px;
      height: 89%;
      overflow-y: scroll;
      .el-tree-node__expand-icon {
        color: #606266;
      }
      .el-tree-node__expand-icon.expanded {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      [class*=" el-icon-"],[class^="el-icon-"] {
        // 更换图标库
        font-family: "FontAwesome" !important;
      }
      .el-icon-caret-right:before {
        // 收起
        content: "\f196";
        font-size: 18px;
      }

      .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
        // 展开
        content: "\f147";
        font-size: 18px;
      }
      .el-tree-node__expand-icon.is-leaf.el-icon-caret-right:before {
        // 叶子节点展开
        content: "";
      }
      .el-tree-node {
        div {
          //&:hover {
          //  background: transparent;
          //}
        }
        .el-tree-node__content {
          font-size: 14px;
          height: 30px;
          .custom-tree-node {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              .el-button {
                margin-left: 40px;
              }
            }
          }
        }
      }
    }
  }
  .dialog {
    .el-dialog__body {
      padding: 10px 20px;
      .prompt {
        height: 28px;
        display: flex;
        align-items: center;
        .el-icon-warning {
          font-size: 28px;
          color: #FF8B35;
          margin-right: 12px;
        }
      }
    }
  }
}
</style>
